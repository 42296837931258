@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    @apply flex items-center justify-center w-screen h-screen overflow-hidden;
}

::-webkit-scrollbar {
    display: none;
}